import React from "react";

import {MainLayout} from "components/Organisms/layouts";
import {Button, Card, Col, Descriptions, Divider, Form, Row, Space,} from "antd";
import CusPageHeader from "components/Molecules/header";
import Page from "components/Molecules/page";
import {useNavigate} from "react-router-dom";
import ComplianceProvider, {useComplianceContext} from "context/Compliance";
import {COMPLIANCE_CHECK, DOCUMENT_REVIEW_COMPLIANCE_CHECK,} from "configs/features";
import ProductDescription from "components/Molecules/form/Sections/ProductDescription";
// import TestPlan from "components/Molecules/form/Sections/TestPlan";
import {Section} from "components/Molecules/form/Sections";
import TestPlan from "./components/TestPlan";
import FormConfigProvider from "components/Molecules/form/FormRenderer/context";
import ReportData from "./components/ReportData";
import styled from "@emotion/styled";
import ModalRejection from "./components/ModalComplianceRejection";
import {ModalComplianceExpiryDate} from "pages/Reviews/components/modal-compliance-expiry-date";
import RejectReasonSection from "pages/Reviews/components/RejectReason";
import {first, get, isEmpty, trim} from "lodash";
import {USER_ROLES} from "pages/Reviews/constants";
import {RbacContext} from "components/RBAC/context";
import {Action, Resource} from "configs/rules";

const FACTORY_SECTION = {
  product_description: ProductDescription,
  reviewer_test_plan: TestPlan,
  reject_reason: RejectReasonSection,
};

const PassButton = styled(Button)`
    border-color: #005eff;
    background: #005eff;
`;
const RejectButton = styled(Button)`
    // background: #ff5555;
`;

const WalmartComplianceCheck = () => {
  const canActivate = React.useContext(RbacContext);

  const isEdit = canActivate(Resource.JOBS, Action.EDIT);

  const {
    product,
    compliance,
    aiResult,
    drAIResult,
    isFetched,
    isUpdateLoading,
    saveChange,
    submitCompliance,
  } = useComplianceContext();

  console.log(aiResult)

  const taskType = compliance?.taskType || compliance?.task?.taskType;

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [, setFieldsChanged] = React.useState([]);
  const [visibleRejectCompliance, setVisibleRejectCompliance] = React.useState(
    false
  );
  const [visiblePassedCompliance, setVisiblePassedCompliance] = React.useState(
    false
  );

  const onSubmit = async (values, isDraft: boolean) => {
    await saveChange(values, isDraft);
    navigate(-1);
  };

  const handleReject = async (values) => {
    setVisibleRejectCompliance(false);
    // const testQuotations = get(values, "testQuotations", []) as any[];

    // const testItems = get(values, "requestTestItems", []) as any[];

    await submitCompliance(form.getFieldsValue(), {
      status: "rejected",
      reject: {
        rejectReason: get(values, "rejectReason").join("|||"),
        // testItems: Array.isArray(testItems)
        //   ? testItems.map(({ testItem }) => {
        //       return testItem as string;
        //     })
        //   : [],
        remark: get(values, "remark"),
        additionDocuments:
          (get(values, "additionalDocuments", []) as any[]) || [],
        // testQuotations: Array.isArray(testQuotations) ? testQuotations : [],
      },
    });
    navigate(-1);
  };

  const handlePass = async (values: { expiryDate: moment.Moment }) => {
    setVisiblePassedCompliance(false);

    const {expiryDate} = values;

    await submitCompliance(form.getFieldsValue(), {
      status: "completed",
      expiryDate: expiryDate.format("YYYY-MM-DD"),
    });
    navigate(-1);
    // submitWithPassed(expiryDate.format("YYYY-MM-DD"));
  };

  React.useEffect(() => {
    if (isFetched) {
      form.setFieldsValue({
        product: {
          ...product,
        },
        ...compliance,
      });
    }
  }, [isFetched]);

  React.useEffect(() => {
    if (isFetched) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        product: {
          ...product,
        },
      });
    }
  }, [product]);

  const checkExist = (value, include?) => {
    if (!isEmpty(include) && !isEmpty(value)) {
      return include.includes(value) &&
      trim(value).toLowerCase() === "validated" ? (
        <span style={{textTransform: "capitalize"}}>
          {value} <span style={{color: "#B0CE52", fontSize: "16px"}}>✓</span>
        </span>
      ) : trim(value).toLowerCase() === "aisuggestion" ?
        <span style={{textTransform: "capitalize"}}>
        </span>
        : (
          <span>
          {value} <span style={{color: "#ED4646"}}>Missing ⚠</span>
        </span>
        );
    }

    return !isEmpty(value) && trim(value).toLowerCase() === "validated" ? (
      <span style={{textTransform: "capitalize"}}>
        {value} <span style={{color: "#B0CE52", fontSize: "16px"}}>✓</span>
      </span>
    ) : trim(value).toLowerCase() === "aisuggestion" ?
      <span style={{color: "#B0CE52", fontSize: "16px"}}>✓</span>
      : (
        <span style={{color: "#ED4646"}}>Missing ⚠</span>
      );
  };

  const renderAboutSection = () => {
    const renderRow = (field: any, index: number) => {
      return (
        <Row key={index} wrap style={{margin: "10px 0"}}>
          <Col span={8}>{field?.key}</Col>
          <Col span={14}>{field?.value}</Col>
          <Col span={2}>{checkExist(field?.result)}</Col>
        </Row>
      );
    };

    return (
      <>
        {(drAIResult || [])?.map((item: any, index: number) => (
          <Descriptions.Item key={index} label={item?.title} span={3}>
            {(item?.sections || []).map((field: any, fieldIndex: number) => {
              if (field.type === "groupValues")
                return (field.value ?? []).map(
                  (valueItem: any, valueIndex: number) => {
                    return (
                      <>
                        {
                          <Row
                            key={valueIndex}
                            wrap
                            style={{margin: "10px 0", fontWeight: 700}}
                          >
                            <Col span={8}>#{valueIndex + 1}</Col>
                          </Row>
                        }
                        {(valueItem.value ?? []).map(renderRow)}
                      </>
                    );
                  }
                );

              if (field.type === "arrayValues")
                return (field.value ?? []).map(renderRow);

              // other types: input, date, tag
              return renderRow(field, fieldIndex);
            })}
          </Descriptions.Item>
        ))}
      </>
    );
  };

  return (
    <MainLayout>
      <CusPageHeader
        title={
          <div>
            Compliance Check <Divider type={`vertical`}/>{" "}
            <span style={{color: "#777777"}}>Requirement Detail</span>
          </div>
        }
        onBack={() => navigate(-1)}
      ></CusPageHeader>
      <Page>
        {isFetched && (
          <Form
            onFieldsChange={(fieldChanged, allFields) => {
              //Trigger Form Changed Value.
              //Force FormRender to re-render with data changed.
              const firstField = first(fieldChanged);
              if (
                firstField &&
                firstField.name.toString()?.includes("reportData") &&
                firstField.value.length &&
                firstField.errors.length
              ) {
                // firstField.errors
                form.setFields(
                  allFields.map((field) => {
                    if (
                      firstField.name.toString()?.includes("reportData") &&
                      firstField.errors.length
                    ) {
                      return {
                        ...field,
                        errors: firstField.value.length ? [] : field.errors,
                      };
                    }
                    return {
                      ...field,
                    };
                  })
                );
              }
              setFieldsChanged([...allFields]);
            }}
            onFinish={(values) => {
              onSubmit(values, false);
            }}
            validateTrigger={["onSubmit"]}
            preserve
            layout="vertical"
            form={form}
            initialValues={{
              product: {
                ...product,
              },
              ...compliance,
            }}
          >
            <FormConfigProvider
              // valuesChanged={{ ...valuesChanged }}
              form={form}
              factory={{
                REPORT_DATA: ReportData,
              }}
            >
              <Card>
                {taskType === "cpcGccReview"
                  ? COMPLIANCE_CHECK.walmart.sections.map((section, index) => {
                    const Element = FACTORY_SECTION[section.sectionType];
                    return (
                      <Form.Item
                        key={index}
                        name={section.name || undefined}
                        shouldUpdate
                      >
                        {Element ? (
                          <Element
                            canActivate={canActivate}
                            task={product}
                            compliance={{...compliance}}
                            section={section}
                            role={USER_ROLES.REVIEWER}
                          ></Element>
                        ) : (
                          <Section
                            resource={Resource.JOBS}
                            action={Action.CREATE}
                            canActivate={canActivate}
                            section={section as any}
                          ></Section>
                        )}
                      </Form.Item>
                    );
                  })
                  : DOCUMENT_REVIEW_COMPLIANCE_CHECK.walmart.sections.map(
                    (section, index) => {
                      const Element = FACTORY_SECTION[section.sectionType];
                      return (
                        <Form.Item
                          key={index}
                          name={section.name || undefined}
                          shouldUpdate
                        >
                          {Element ? (
                            <Element
                              canActivate={canActivate}
                              task={product}
                              compliance={{...compliance}}
                              section={section}
                              role={USER_ROLES.REVIEWER}
                            ></Element>
                          ) : (
                            <Section
                              resource={Resource.JOBS}
                              action={Action.CREATE}
                              canActivate={canActivate}
                              section={section as any}
                            ></Section>
                          )}
                        </Form.Item>
                      );
                    }
                  )}
              </Card>
              {
                aiResult?.groupId &&
                <Card>
                  <CusTable>
                    <h2>
                      Products{" "}
                      <span>
                      <a
                        style={{fontSize: 14, fontWeight: 400}}
                        href={`https://dev.dr.icw.io/file/group/${aiResult?.groupId}/?redirectURL=${window.location.href}`}
                        target="_blank"
                      >
                        {" "}
                        <img
                          src="/icons/ai-stars.svg"
                          alt="ai icon"
                          style={{marginTop: "-4px"}}
                        />{" "}
                        view AI result
                      </a>
                    </span>
                    </h2>
                    <tr>
                      <th>Product Name</th>
                      <th>Product Number</th>
                      <th>UPC</th>
                      <th>Remark</th>
                    </tr>
                    {(aiResult?.productList || []).map((item) => (
                      <tr>
                        <td>{item.productName}</td>
                        <td>
                          {/*{checkExist(item.itemNumber, aiResult?.modelNumber)}*/}
                          {item.itemNumber}
                        </td>
                        <td>
                          {/*{checkExist(item.upc, aiResult.UPC)}*/}
                          {item.upc}
                        </td>
                        <td></td>
                      </tr>
                    ))}
                  </CusTable>

                  <h2>About</h2>
                  <CusDescription title="" layout="vertical" bordered>
                    {renderAboutSection()}
                  </CusDescription>

                  {/*<CusTable>*/}
                  {/*  <h2>Test Record(s)</h2>*/}
                  {/*  <tr>*/}
                  {/*    <th colSpan={2}>Test Item</th>*/}
                  {/*    <th colSpan={3}>Name of Third Party Institution</th>*/}
                  {/*    <th>Report Number</th>*/}
                  {/*    <th style={{ width: "100px" }}>Issue Date</th>*/}
                  {/*  </tr>*/}
                  {/*  {(aiResult?.testItem || []).map((item) => (*/}
                  {/*    <tr>*/}
                  {/*      <td colSpan={2}>{item}</td>*/}
                  {/*      <td colSpan={3}>*/}
                  {/*        {checkExist(aiResult?.testingLabName)}*/}
                  {/*      </td>*/}
                  {/*      <td>N/A</td>*/}
                  {/*      <td style={{ width: "100px" }}>*/}
                  {/*        {checkExist(aiResult?.testingDate)}*/}
                  {/*      </td>*/}
                  {/*    </tr>*/}
                  {/*  ))}*/}
                  {/*</CusTable>*/}
                </Card>
              }
            </FormConfigProvider>

            <Row
              hidden={!isEdit || product?.status === "completed"}
              justify="space-between"
              align="middle"
              style={{margin: "40px 0"}}
            >
              <Col flex={"none"}>
                <Space size={16}>
                  <PassButton
                    size={`large`}
                    type={`primary`}
                    loading={isUpdateLoading}
                    onClick={async () => {
                      try {
                        await form.validateFields();
                        setVisiblePassedCompliance(true);
                        // setVisibleComplianceExpiryDate(true);
                      } catch (error) {
                        const {errorFields = []} = error as any;
                        if (errorFields.length) {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }
                      }
                    }}
                  >
                    Approved
                  </PassButton>
                  <RejectButton
                    size={`large`}
                    danger
                    type="primary"
                    loading={isUpdateLoading}
                    onClick={() => {
                      setVisibleRejectCompliance(true);
                    }}
                  >
                    Rejected
                  </RejectButton>
                </Space>
              </Col>
              <Col flex={"none"}>
                <Button
                  size={`large`}
                  loading={isUpdateLoading}
                  onClick={() => onSubmit(form.getFieldsValue(), true)}
                >
                  Save draft
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Page>
      <ModalRejection
        onCancel={() => {
          setVisibleRejectCompliance(false);
        }}
        visible={visibleRejectCompliance}
        onSubmit={handleReject}
      ></ModalRejection>
      <ModalComplianceExpiryDate
        onCancel={() => {
          setVisiblePassedCompliance(false);
        }}
        onSubmit={handlePass}
        visible={visiblePassedCompliance}
      ></ModalComplianceExpiryDate>
    </MainLayout>
  );
};

const WrapContext = (props) => {
  return (
    <ComplianceProvider>
      <WalmartComplianceCheck {...props}></WalmartComplianceCheck>
    </ComplianceProvider>
  );
};
export default WrapContext;

const CusDescription = styled(Descriptions)`
    .ant-descriptions-view {
        border: none !important;
    }

    th {
        //border: solid 1px #d9d9d9;
        padding: 15px;
        text-align: left;
        background: #ebf1ff !important;
        font-size: 14px;
    }

    tr {
        color: #4e5a71;
        border: none !important;
    }

    td {
        border: none !important;
        font-size: 12px;
        color: #4e5a71;
    }
`;

const CusTable = styled.table`
    border-collapse: collapse;
    table-layout: fixed;
    margin: 30px 0;
    width: 100%;

    th {
        //border: solid 1px #d9d9d9;
        font-size: 14px;
        line-height: 1.2;
        font-weight: 400;
        padding: 15px 20px;
        text-align: left;
        background: #ebf1ff;
    }

    td {
        //overflow: hidden;
        padding: 20px 20px 0 20px;
        word-wrap: break-word;
        font-size: 12px;
        color: #4e5a71;
    }
`;
