import qs from "qs";
import * as auth from "auth-provider";
import { useAuth } from "context/auth-context";
import { useCallback } from "react";
import isEmpty from "lodash/isEmpty";

const apiUrl = process.env.REACT_APP_API_URL;

interface Config extends RequestInit {
  token?: string;
  data?: object | FormData;
}

export const http = async (
  endpoint: string,
  { data, token, headers, ...customConfig }: Config = {},
  url = apiUrl
) => {
  const config = {
    method: "GET",
    headers: headers || {
      Token: token ? `${token}` : "",
      "Content-Type": data ? "application/json" : "",
    },
    ...customConfig,
  };

  if (data instanceof FormData) {
    // @ts-ignore
    delete config.headers["Content-Type"];
  }

  if (config.method.toUpperCase() === "GET") {
    if (!isEmpty(data)) {
      endpoint += `?${qs.stringify(data, {
        encodeValuesOnly: false,
        indices: false,
      })}`;
    }
  } else {
    config.body = data instanceof FormData ? data : JSON.stringify(data || {});
  }

  return window.fetch(`${url}/${endpoint}`, config).then(async (response) => {
    const data = await response.json();

    if (
      (data.message && data.message.indexOf("invalid token") > -1) ||
      data.status === 401
    ) {
      await auth.logout();
      window.location.reload();
      return Promise.reject({ message: "Please login again" });
    }
    if (
      [200, 201].includes(data.status) ||
      [200, 201].includes(data.statusCode)
    ) {
      return data.data;
    } else {
      return Promise.reject(data);
    }
  });
};

export const httpDownload = async (
  endpoint: string,
  { data, token, headers, ...customConfig }: Config = {},
  url = apiUrl
) => {
  const config = {
    method: "POST",
    headers: {
      Token: token ? `${token}` : "",
      // "Content-Type": "application/json",
    },
    responseType: "blob",
    ...customConfig,
  };

  if (config.method.toUpperCase() === "GET") {
    // endpoint += `?${qs.stringify(data)}`;
  } else {
    config.body = JSON.stringify(data || {});
  }

  return window
    .fetch(`${url}/${endpoint}`, config)
    .then((response) => response.blob());
  // .then((blob) => blobToBase64(blob))
  // .then((base64) => base64);
};

function blobToBase64(blob: Blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      resolve(reader.result as string);
    };
  });
}

// return typeof 1 === 'number'
// return (...[endpoint, config]: Parameters<typeof http>) =>
export const useHttp = (url = apiUrl) => {
  const { user } = useAuth();

  return useCallback(
    (...[endpoint, config]: Parameters<typeof http>) =>
      http(endpoint, { ...config, token: user?.token }, url),
    [user?.token]
  );
};

export const useHttpDownload = (url = apiUrl) => {
  const { user } = useAuth();

  return useCallback(
    (...[endpoint, config]: Parameters<typeof http>) =>
      httpDownload(endpoint, { ...config, token: user?.token }, url),
    [user?.token]
  );
};
