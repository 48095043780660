import { useHttp } from "../../../utils/http";
import { useAsync } from "../../../utils/use-async";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { cleanObject, useDebounce } from "../../../utils";
import {
  useProductGroups,
  useSubCategories,
} from "components/Molecules/select/api";
import { StaticDataContext } from "context/static-data-context";

export const useTaskCompliance = (param: Partial<any>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<any>();

  const fetchTaskCompliance = useCallback(
    () => client(`tasks/${param.taskId}/compliance`, {}),
    [param, client]
  );

  useEffect(() => {
    run(fetchTaskCompliance(), {
      retry: fetchTaskCompliance,
    });
  }, [param, run, fetchTaskCompliance]);

  return result;
};

export const useTaskComplianceAIResult = (param: Partial<any>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<any>();
  const { taskId } = param;

  const fetchTaskComplianceAIResult = useCallback(
    () => client(`qmas-ai/${taskId}`, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [param, client]
  );

  useEffect(() => {
    run(fetchTaskComplianceAIResult(), {
      retry: fetchTaskComplianceAIResult,
    });
  }, [param, run, fetchTaskComplianceAIResult]);

  return result;
};

export const useDocumentReviewerAIGroupData = (param: Partial<any>) => {
  const client = useHttp(process.env.REACT_APP_DR_API_URL);
  const { run, ...result } = useAsync<any>();

  const fetchData = useCallback(
    () =>
      client(`file/group/${param.groupId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          "X-Access-Key-Id": process.env.REACT_APP_DR_X_ACCESS_KEY_ID,
          "X-Secret-Key": process.env.REACT_APP_DR_X_SECRET_KEY,
        },
      }),
    [param.groupId, client]
  );

  useEffect(() => {
    if (!param.groupId) return;
    run(fetchData(), {
      retry: fetchData,
    });
  }, [param.groupId, run, fetchData]);

  return result;
};

export const useDocumentReviewerAIResult = (param: Partial<any>) => {
  const client = useHttp(process.env.REACT_APP_DR_API_URL);
  const { run, ...result } = useAsync<any>();

  const fetchData = useCallback(
    () =>
      client(`file/${param.fileId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          "X-Access-Key-Id": process.env.REACT_APP_DR_X_ACCESS_KEY_ID,
          "X-Secret-Key": process.env.REACT_APP_DR_X_SECRET_KEY,
        },
      }),
    [param.fileId, client]
  );

  useEffect(() => {
    if (!param.fileId) return;
    run(fetchData(), {
      retry: fetchData,
    });
  }, [param.fileId, run, fetchData]);

  return result;
};

export const useTaskComplianceSummary = (param: Partial<any>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<any>();
  const { taskId } = param;

  const fetchTaskComplianceSummary = useCallback(
    () => client(`tasks/${taskId}/compliance_summary`, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [param, client]
  );

  useEffect(() => {
    run(fetchTaskComplianceSummary(), {
      retry: fetchTaskComplianceSummary,
    });
  }, [param, run, fetchTaskComplianceSummary]);

  return result;
};

export const useUpdateTaskCompliance = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = ({
    params,
    taskId,
  }: {
    params?: Partial<any>;
    taskId: string;
  }) => {
    return run(
      client(`tasks/${taskId}/compliance`, {
        data: cleanObject(params || {}),
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useFetchSubCategoriesAndProductGroup = (
  category: string,
  subCategory: string
) => {
  const { productCategories, exportMarkets } = useContext(StaticDataContext);
  const categoryId = useMemo(() => {
    return (
      (productCategories || []).find(({ name }) => name === category)?.id ?? ""
    );
  }, [category, productCategories]);

  const { data: subCategories } = useSubCategories(useDebounce(categoryId, 0));

  const subCategoryId = useMemo(() => {
    return (
      (subCategories || []).find(({ name }) => name === subCategory)?.id ?? ""
    );
  }, [subCategory, subCategories]);

  const { data: productGroup } = useProductGroups(
    useDebounce(subCategoryId, 0)
  );
  return [productCategories, subCategories, productGroup, exportMarkets];
};
